// DEV
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Link from '@material-ui/core/Link'
import Grid from '@material-ui/core/Grid'
import {
  reduxForm, formValueSelector, change, Field
} from 'redux-form'
import { ReCaptcha } from 'react-recaptcha-google'
// APP
import formOptions, { adSpaceTypes } from '../formOptions'
import { RECAPTCHA_SITE_KEY } from '../../config-frontend'
import Contact from './Contact'
import FormCheckbox from './FormCheckbox'

const formSelector = formValueSelector(formOptions.form)

const useStyles = makeStyles((theme) => ({
  listItem: {
    padding: theme.spacing(1, 0),
  },
  total: {
    fontWeight: 700,
  },
  title: {
    marginTop: theme.spacing(2),
  },
}))

function currency(number) {
  const formatedNumber = new Intl.NumberFormat('cs-CZ', { style: 'currency', currency: 'CZK' }).format(number)
  return formatedNumber
}

function Review(props) {
  let itepCaptcha = null
  const dispatch = useDispatch()
  const { setRecaptchaInitialized } = props
  const products = []

  const classes = useStyles()
  const values = useSelector((state) => formSelector(
    state,
    'obchodniJmeno', 'ico', 'dic', 'ulice', 'mesto', 'psc', 'kontaktniOsoba', 'telefon', 'email', 'poznamka'
  ))
  const typVystavniPlochy = useSelector((state) => formSelector(
    state,
    'typVystavniPlochy'
  ))

  const pocetStanku = useSelector((state) => formSelector(
    state,
    'pocetStanku'
  ))

  const pocetM2 = useSelector((state) => formSelector(
    state,
    'pocetM2'
  ))

  const addresses = [values.ulice, values.mesto, values.psc]

  const adSpaceUnits = parseInt(typVystavniPlochy === 'a' ? pocetStanku : pocetM2)
  const price = currency(adSpaceTypes[typVystavniPlochy].unitPrice * adSpaceUnits)
  products.push({
    name: adSpaceTypes[typVystavniPlochy].title,
    desc: `${adSpaceUnits} x ${adSpaceTypes[typVystavniPlochy].unitPrice}`,
    price
  })

  const verifyCallback = (recaptchaToken) => {
    dispatch(change(formOptions.form, 'recaptchaToken', recaptchaToken))
    setRecaptchaInitialized(true)
  }

  return (
    <>
      <Typography variant="h6" gutterBottom>
        Shrnutí přihlášky
      </Typography>
      <List disablePadding>
        {products.map((product) => (
          <ListItem className={classes.listItem} key={product.name}>
            <ListItemText primary={product.name} secondary={product.desc} />
            <Typography variant="body2">{product.price}</Typography>
          </ListItem>
        ))}
        <ListItem className={classes.listItem}>
          <ListItemText primary="Celkem (bez DPH)" />
          <Typography variant="subtitle1" className={classes.total}>
            {price}
          </Typography>
        </ListItem>
      </List>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography gutterBottom>
            <strong>Firma</strong>
          </Typography>
          <Typography gutterBottom>{values.obchodniJmeno}</Typography>
          <Typography gutterBottom>
            IČO:
            {values.ico}
          </Typography>
          <Typography gutterBottom>
            DIČ:
            {values.dic}
          </Typography>
          <Typography gutterBottom>{addresses.join(', ')}</Typography>
        </Grid>
        <Grid item container direction="column" xs={12} sm={6}>
          <Typography gutterBottom>
            <strong>Kontaktní osoba</strong>
          </Typography>
          <Grid container>
            <Grid item xs={12}>
              <Typography gutterBottom>{values.kontaktniOsoba}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography gutterBottom>{values.telefon}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography gutterBottom>{values.email}</Typography>
            </Grid>
          </Grid>
        </Grid>
        {values.poznamka && (
        <Grid item xs={12}>
          <Typography gutterBottom>
            <strong>Poznámka</strong>
          </Typography>
          <Typography gutterBottom>{values.poznamka}</Typography>
        </Grid>
        ) }
        <Grid item xs={12}>
          <Field
            name="souhlasVP"
            component={FormCheckbox}
            label="Souhlasím s GDPR (součástí VP) a s všeobecnými podmínkami ITEP 2020"
            fullWidth
          />
          <Link href="/VP.pdf" target="_blank">Všeobecné podmínky ITEP 2020</Link>
        </Grid>
        <Grid tem xs={12}>
          {/* <Grid container item justify="center"> */}
          <ReCaptcha
            ref={(el) => { itepCaptcha = el }}
            size="normal"
            data-theme="dark"
            render="explicit"
            sitekey={RECAPTCHA_SITE_KEY}
            verifyCallback={verifyCallback}
          />
        </Grid>

      </Grid>

      <Contact />

    </>
  )
}

export default reduxForm(formOptions)(Review)
