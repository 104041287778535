// DEV
import React from 'react'
import { Field, reduxForm } from 'redux-form'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
// APP
import FormTextField from './FormTextField'
import FormSelectField from './FormSelectField'
import formOptions from '../formOptions'

const regions = [
  { name: 'Hlavní město Praha', id: 'PHA' },
  { name: 'Středočeský kraj', id: 'STC' },
  { name: 'Jihočeský kraj', id: 'JHC' },
  { name: 'Plzeňský kraj', id: 'PLK' },
  { name: 'Karlovarský kraj', id: 'KVK' },
  { name: 'Ústecký kraj', id: 'ULK' },
  { name: 'Liberecký kraj', id: 'LBK' },
  { name: 'Královéhradecký kraj', id: 'HKK' },
  { name: 'Pardubický kraj', id: 'PAK' },
  { name: 'Vysočina', id: 'VYS' },
  { name: 'Jihomoravský kraj', id: 'JHM' },
  { name: 'Olomoucký kraj', id: 'OLK' },
  { name: 'Zlínský kraj', id: 'ZLK' },
  { name: 'Moravskoslezský kraj', id: 'MSK' },
]

const regionsOptions = regions.map((r) => ({ value: r.id, text: r.name }))

function AddressForm() {
  return (
    <>
      <Typography variant="h6" gutterBottom>
        Firemní údaje
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Field
            name="obchodniJmeno"
            type="text"
            component={FormTextField}
            label="Obchodní jméno"
            helperText="dle obchodního rejstříku/živnostenského listu"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field
            name="ico"
            type="text"
            component={FormTextField}
            label="IČO"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field
            name="dic"
            type="text"
            component={FormTextField}
            label="DIČ"
            fullWidth
            helperText="vždy uvádějte pokud jste plátci DPH"
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">
            Adresa sídla
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Field
            name="ulice"
            type="text"
            component={FormTextField}
            label="Ulice a číslo popisné"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field
            name="mesto"
            type="text"
            component={FormTextField}
            label="Město"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field
            name="psc"
            type="text"
            component={FormTextField}
            label="PSČ"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field
            name="kraj"
            type="text"
            component={FormSelectField}
            options={regionsOptions}
            placeholder="Vyberte kraj"
            label="Kraj"
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">
            Kontaktní údaje
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field
            name="kontaktniOsoba"
            type="text"
            component={FormTextField}
            label="Kontaktní osoba"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field
            name="telefon"
            type="text"
            component={FormTextField}
            label="Telefon"
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            name="email"
            type="text"
            component={FormTextField}
            label="E-mail"
            fullWidth
            helperText="na tento e-mail budou zasílány veškeré informace, po veletrhu na něj bude zaslána i faktura"
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            name="poznamka"
            type="text"
            component={FormTextField}
            label="Poznámka"
            fullWidth
            helperText="požadavky vystavovatele k faktuře – č. objednávky, text k projektu, jiný zasílací e-mail pro fakturu, apod."
            multiline
            rows="4"
            variant="outlined"
          />
        </Grid>
        {/* <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox color="secondary" name="saveAddress" value="yes" />}
            label="Use this address for payment details"
          />
        </Grid> */}
      </Grid>
    </>
  )
}

export default reduxForm(formOptions)(AddressForm)
