import rq from 'request-promise'
import { BASE_URI } from '../config-frontend'
// const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

export default (async function formSubmit(values, callback) {
  const url = `${BASE_URI}/submit`

  const options = {
    method: 'POST',
    uri: url,
    body: {
      ...values
    },
    json: true // Automatically stringifies the body to JSON
  }

  // await sleep(2000); // simulate server latency
  // callback({success: true, message: "Úspěch"})
  try {
    const response = await rq(options)
    callback(response)
    console.log(response)
  } catch (error) {
    console.log('ERROR', error)
    callback(error.error)
  }
})
