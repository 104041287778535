const validate = (values) => {
  const errors = {}
  if (!values.obchodniJmeno) {
    errors.obchodniJmeno = 'Povinné'
  }

  if (!values.obchodniJmeno) {
    errors.obchodniJmeno = 'Povinné'
  }

  if (!values.ico) {
    errors.ico = 'Povinné'
  }

  if (!values.ulice) {
    errors.ulice = 'Povinné'
  }

  if (!values.mesto) {
    errors.mesto = 'Povinné'
  }

  if (!values.psc) {
    errors.psc = 'Povinné'
  }

  if (!values.kraj) {
    errors.kraj = 'Povinné'
  }

  if (!values.kontaktniOsoba) {
    errors.kontaktniOsoba = 'Povinné'
  }

  if (!values.telefon) {
    errors.telefon = 'Povinné'
  }

  if (!values.email) {
    errors.email = 'Povinné'
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Neplatná e-mailová adresa'
  }

  if (!values.typVystavniPlochy) {
    errors.typVystavniPlochy = 'Povinné'
  }

  if (values.typVystavniPlochy && values.typVystavniPlochy === 'a') {
    if (!values.pocetStanku) {
      errors.pocetStanku = 'Povinné'
    } else {
      const pocetStanku = parseInt(values.pocetStanku)
      if (isNaN(pocetStanku)) {
        errors.pocetStanku = 'Počet stánků musí být celé číslo'
      } else if (pocetStanku < 1) {
        errors.pocetStanku = 'Počet stánků musí být větší než 1'
      }
    }
  }

  if (values.typVystavniPlochy && values.typVystavniPlochy === 'b') {
    if (!values.pocetM2) {
      errors.pocetM2 = 'Povinné'
    } else {
      const pocetM2 = parseInt(values.pocetM2)
      if (isNaN(pocetM2)) {
        errors.pocetM2 = 'Počet m2 musí být celé číslo'
      } else if (pocetM2 < 1) {
        errors.pocetM2 = 'Počet m2 musí být větší než 1'
      }
    }
  }

  if (!values.souhlasVP) {
    errors.souhlasVP = 'Povinné'
  } else if (values.souhlasVP !== true) {
    errors.souhlasVP = 'Musíte souhlasit s všeobecnými podmínkami'
  }

  return errors
}

export const options = {
  form: 'itep', // <------ same form name
  destroyOnUnmount: false, // <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate
}

export const adSpaceTypes = {
  a: {
    id: 'a',
    title: 'Výstavní stánek v hale č. 1',
    unitPrice: 6500
  },
  b: {
    id: 'b',
    title: 'Výstavní plochu v hale č. 2',
    unitPrice: 1000
  }
}

export default options
