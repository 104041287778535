// DEV
import React from 'react'
import { Field, reduxForm, formValueSelector } from 'redux-form'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { useSelector } from 'react-redux'

// APP
import FormTextField from './FormTextField'
import FormAdVariant from './FormAdVariant'
import formOptions, { adSpaceTypes } from '../formOptions'

const formSelector = formValueSelector(formOptions.form)

function AdSpaceForm() {
  const typVystavniPlochy = useSelector((state) => formSelector(state, 'typVystavniPlochy'))
  const objednavka = typVystavniPlochy === 'a' ? adSpaceTypes.a.title : adSpaceTypes.b.title

  return (
    <>
      <Typography variant="h6" gutterBottom>
        Objednávám
        {' '}
        {objednavka}
      </Typography>
      <Grid container spacing={2}>
        <Field name="typVystavniPlochy" component={FormAdVariant} />
        {/* <Grid item xs={12}>
          { typVystavniPlochy === 'a' && (
            <Field
              name="pocetStanku"
              type="text"
              component={FormTextField}
              label="Počet stánků"
              fullWidth
            />
          )}
          { typVystavniPlochy === 'b' && (
            <Field
              name="pocetM2"
              type="text"
              component={FormTextField}
              label="Počet m2"
              fullWidth
            />
          )}
        </Grid> */}
      </Grid>
    </>
  )
}

export default reduxForm(formOptions)(AdSpaceForm)
