import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  title: {
    marginTop: theme.spacing(2),
  },
}))

export default function Contact() {
  const classes = useStyles()

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom className={classes.title}>
          Kontaktní adresa a osoby
        </Typography>
        <Typography><strong>Krajský úřad Plzeňského kraje</strong></Typography>
        <Typography>Odbor kultury, památkové péče a cestovního ruchu</Typography>
        <Typography>oddělení cestovního ruchu</Typography>
        <Typography>Škroupova 18, Plzeň 306 13</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography gutterBottom>
          <strong>Bc. Filip Beránek</strong>
        </Typography>

        <Typography variant="subtitle1">
          realizace expozic, příjem přihlášek, komunikace s vystavovateli
        </Typography>
        <Typography>tel.: 377 195 284, 733 698 664</Typography>
        <Typography>e-mail: filip.beranek@plzensky-kraj.cz</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography gutterBottom>
          <strong>Bc. Ondřej Čech</strong>
        </Typography>
        <Typography variant="subtitle1">
          fakturace
        </Typography>
        <Typography>tel.: 377 195 386, 777 353 667</Typography>
        <Typography>e-mail: ondrej.cech@plzensky-kraj.cz</Typography>
      </Grid>
      {/* <Grid item xs={12}>
        <Link href="/VP.pdf">Všeobecné podmínky ITEP 2020</Link>
      </Grid> */}
    </Grid>
  )
}
