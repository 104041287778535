import React from 'react'
import Typography from '@material-ui/core/Typography'

export default function ThankYou() {
  return (
    <>
      <Typography variant="h5" gutterBottom>
        Děkujeme vám za přihlášku na ITEP 2020!
      </Typography>
      <Typography variant="subtitle1">
        Na zadaný e-mail bylo odesláno potvrzení se shrnutím Vámi vyplněných údajů.
      </Typography>
    </>
  )
}
