// DEV
import React from 'react'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'

export default function Credits() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'vytvořeno '}
      <Link color="inherit" href="https://www.wense.cz">
        Wense
      </Link>
    </Typography>
  );
}