// DEV
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import CssBaseline from '@material-ui/core/CssBaseline'
import 'regenerator-runtime/runtime'
// APP
import App from './views/App'
// eslint-disable-next-line import/no-named-as-default
import store from './stores/rootStore'

ReactDOM.render(
  <Provider store={store}>
    <CssBaseline />
    <App />
  </Provider>,
  document.querySelector('#root'),
)

// Needed for Hot Module Replacement
if (typeof (module.hot) !== 'undefined') {
  module.hot.accept() // eslint-disable-line no-undef
}
