/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'

const FormSelectField = ({
  label,
  input,
  helperText,
  options,
  placeholder,
  meta: { touched, invalid, error },
  ...custom
}) => {
  const renderOptions = options.map((o) => <MenuItem value={o.value} key={o.value}>{o.text}</MenuItem>)
  return (
    <>
      <InputLabel id="">{label}</InputLabel>
      <Select
        error={touched && invalid}
        helperText={(touched && error) || helperText}
        {...input}
        {...custom}
      >
        <MenuItem value="" disabled>
          {placeholder}
        </MenuItem>
        {renderOptions}
      </Select>
    </>
  )
}

export default FormSelectField
