// DEV
import React from 'react'
import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import { Field } from 'redux-form'
import { adSpaceTypes } from '../formOptions'
import FormTextField from './FormTextField'

const FormAdVariant = ({ input, name }) => (
  <>
    <Grid item xs={12}>
      <FormControlLabel control={<Radio checked={input.value === 'a'} onChange={input.onChange} name={name} />} value="a" label={`A): ${adSpaceTypes.a.title}`} />
      {input.value === 'a' && (
      <>
        <Field
          name="pocetStanku"
          type="text"
          component={FormTextField}
          label="Počet stánků"
          labelStrong="Počet stánků"
          fullWidth
        />
        <p><strong>REALIZAČNÍ CENA Kč 6.500,- + DPH v zákonné výši</strong></p>
        <p>
          <strong>Realizační cena zahrnuje:</strong>
          <br />
          pronájem výstavního stánku, stavbu expozice (montáž, údržba, demontáž) 
          základní vybavení (informační pult, barová židle, koberec, nasvícení expozice,
          místo pro jednání), označení výstavní expozice, využití
          společného zázemí (sklad propagačních materiálů), 1x vstupenku na slavnostní večer, úklid,
          ostrahu expozice mimo otevírací hodiny veletrhu.
        </p>
        <p>
          <strong>Realizační cena nezahrnuje:</strong>
          <br />
          zhotovení grafiky k informačním pultům a tisk velkoplošných fotografií,
          vystavovatel hradí přímo dodavateli těchto služeb (realizační firmě)
        </p>
      </>
      )}
    </Grid>
    <Grid item xs={12}>
      <FormControlLabel control={<Radio checked={input.value === 'b'} onChange={input.onChange} name={name} />} value="b" label={`B): ${adSpaceTypes.b.title}`} />
      {input.value === 'b' && (
      <>
        <Field
          name="pocetM2"
          type="text"
          component={FormTextField}
          label="Počet m2"
          fullWidth
        />
        <p><strong>REALIZAČNÍ CENA - Kč 1.000,- + DPH v zákonné výši / m2</strong></p>
        <p>
          <strong>Realizační cena zahrnuje:</strong>
          <br />
          pronájem výstavní plochy, 1x vstupenku na slavnostní večer, koberec, úklid, 
          ostrahu expozice mimo otevírací hodiny veletrhu, elektřinu, osvětlení.
        </p>
        <p>
          <strong>Realizační cena nezahrnuje:</strong>
          <br />
          zhotovení grafiky k informačním pultům a tisk velkoplošných fotografií,
          vystavovatel hradí přímo dodavateli těchto služeb (realizační firmě)
        </p>
      </>
      )}
    </Grid>
  </>
)

export default FormAdVariant
