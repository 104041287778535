// import dotenv from 'dotenv'
import dotenv from 'dotenv-flow'

const env = dotenv.config()
const isDev = process.env.NODE_ENV === 'development'

export const { OUTGOING_EMAIL_ADDRESS } = process.env
export const { CONTACT_EMAIL_ADDRESS } = process.env
export const { NOTIFICATION_EMAIL_ADDRESSES } = process.env
export const { SENDGRID_EMAIL_TEMPLATE_ID } = process.env
export const { AZURE_STORAGE_ACCOUNT } = process.env
export const { AZURE_APPLICATION_TABLE } = process.env
export const RECAPTCHA_SITE_KEY = isDev ? '6Ld04uEUAAAAAJXYndlM7vcFbHEm7xvmQxm6aOWV' : process.env.RECAPTCHA_SITE_KEY
export const BASE_URI = isDev ? 'http://localhost:8080' : process.env.BASE_URI
// SECRETS
export const { AZURE_STORAGE_ACCOUNT_KEY } = process.env
export const { SENDGRID_API_KEY } = process.env
export const { RECAPTCHA_SECRET } = process.env
