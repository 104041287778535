// DEP
import { createStore, applyMiddleware, compose } from 'redux'
import { reducer as reduxFormReducer } from 'redux-form'
import createSagaMiddleware from 'redux-saga'
import throttle from 'lodash/throttle'
// APP
import { loadState, saveState } from './persistState'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const sagaMiddleware = createSagaMiddleware()
const middlewares = [sagaMiddleware]

const combineReducers = (reducers) => (state = {}, action) => Object.keys(reducers).reduce(
  (nextState, key) => {
    nextState[key] = reducers[key](
      state[key],
      action,
      state
    )
    return nextState
  },
  {}
)

const combinedReducer = combineReducers(
  {
    form: reduxFormReducer,
  }
)

const persistedState = loadState()
export const store = createStore(
  combinedReducer,
  persistedState,
  composeEnhancers(applyMiddleware(
    ...middlewares
  )),
)

store.subscribe(throttle(() => {
  const { itep } = store.getState().form
  const values = itep && itep.values ? itep.values : {}
  const form = {
    itep: {
      values: { ...values }
    }
  }

  saveState({
    form
  })
}, 1000))

export default store
