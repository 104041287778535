// DEV
import React, { useState, useEffect } from 'react'
import { reduxForm, destroy, formValueSelector } from 'redux-form'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Paper from '@material-ui/core/Paper'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import { loadReCaptcha } from 'react-recaptcha-google'
import LinearProgress from '@material-ui/core/LinearProgress'
import Alert from '@material-ui/lab/Alert'
// APP
import CompanyForm from './CompanyForm'
import AdSpaceForm from './AdSpaceForm'
import Review from './Review'
import Credits from './Credits'
import ThankYou from './ThankYou'
import formOptions from '../formOptions'
import formSubmit from '../formSubmit'
import { BASE_URI } from '../../config'

const formSelector = formValueSelector(formOptions.form)

// APP
const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}))

const steps = ['Firemní údaje', 'Výstavní plocha', 'Shrnutí']

function getStepContent(step, setRecaptchaInitialized) {
  switch (step) {
    case 0:
      return <CompanyForm />
    case 1:
      return <AdSpaceForm />
    case 2:
      return <Review setRecaptchaInitialized={setRecaptchaInitialized} />
    default:
      throw new Error('Unknown step')
  }
}


function PrevNextButtons(props) {
  const {
    activeStep, handleBack, handleNext, classes, disabled
  } = props
  const { handleSubmit } = props

  return (
    <>
      <form onSubmit={handleSubmit}>
        {activeStep !== 0 && (
          <Button onClick={handleBack} className={classes.button}>
            Zpět
          </Button>
        )}
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          type="submit"
          disabled={disabled}
        >
          {activeStep === steps.length - 1 ? 'Odeslat přihlášku' : 'Dále'}
        </Button>
      </form>
    </>
  )
}

const PrevNextButtonsForm = reduxForm(formOptions)(PrevNextButtons)

export default function App() {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [mounted, setMounted] = useState(false)
  const [requesting, setRequesting] = useState(false)
  const [activeStep, setActiveStep] = React.useState(0)
  const [recaptchaInitialized, setRecaptchaInitialized] = useState(false)
  const [result, setResult] = useState(undefined)
  const reviewScreen = activeStep === steps.length - 1

  const souhlasVP = useSelector((state) => formSelector(
    state, 'souhlasVP'
  ))

  const handleNext = () => {
    setResult(undefined)
    setActiveStep(activeStep + 1)
  }

  const handleBack = () => {
    setResult(undefined)
    setActiveStep(activeStep - 1)
  }

  useEffect(() => {
    if (mounted) return
    loadReCaptcha()
    setMounted(true)
  })

  const submitCallback = (response) => {
    setRequesting(false)
    if (response.success) {
      setResult(undefined)
      setActiveStep(steps.length)
      dispatch(destroy('itep'))
    } else {
      setResult(response)
    }
  }

  const handleSubmission = (values) => {
    setRequesting(true)
    formSubmit(values, submitCallback)
  }

  return (
    <>
      <AppBar position="absolute" color="default" className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" color="inherit" noWrap>
            <Link href={BASE_URI} color="inherit" underline="none">
              ITEP 2020 - Plzeňský kraj
            </Link>
          </Typography>
        </Toolbar>
      </AppBar>
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Typography component="h1" variant="h4" align="center">
            Přihláška na ITEP 2020
          </Typography>
          <Stepper activeStep={activeStep} className={classes.stepper}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          { requesting && <LinearProgress /> }

          <>
            {activeStep === steps.length ? (
              <ThankYou />
            ) : (
              <>
                {getStepContent(activeStep, setRecaptchaInitialized)}
                { result !== undefined
                && (
                <>
                  <Alert severity="error">{result.message}</Alert>
                </>
                )}
                <div className={classes.buttons}>
                  <PrevNextButtonsForm
                    handleBack={handleBack}
                    handleNext={handleNext}
                    classes={classes}
                    activeStep={activeStep}
                    onSubmit={reviewScreen ? handleSubmission : handleNext}
                    disabled={(reviewScreen && !recaptchaInitialized) || (reviewScreen && !souhlasVP)}
                  />
                </div>
              </>
            )}
          </>
        </Paper>
        <Credits />
      </main>
    </>
  )
}
