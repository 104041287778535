import React from 'react'
import TextField from '@material-ui/core/TextField'

const FormTextField = ({
  label,
  input,
  helperText,
  meta: { touched, invalid, error },
  ...custom
}) => (
  <TextField
    label={label}
    placeholder={label}
    error={touched && invalid}
    helperText={(touched && error) || helperText}
    {...input}
    {...custom}
  />
)

export default FormTextField
